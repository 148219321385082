import { render, staticRenderFns } from "./ViewJudgeAssignment.vue?vue&type=template&id=13bf4251&scoped=true&"
import script from "./ViewJudgeAssignment.vue?vue&type=script&lang=ts&"
export * from "./ViewJudgeAssignment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13bf4251",
  null
  
)

export default component.exports







import ProjectListing from "@/components/ProjectListing.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Application,
  GetJudgeAssignmentParams
} from "@/state/Application/application";
import { getModule } from "vuex-module-decorators";
import { Unit } from "@/entities";
import { namespace } from "vuex-class";

const ApplicationStore: Application = getModule(Application);
const Store = namespace("Application");

@Component({
  components: {
    ProjectListing
  }
})
export default class ViewJudgeAssignment extends Vue {
  @Prop({ required: true })
  public unitId!: string;

  @Prop({ required: true })
  public judgeId!: string;

  mounted() {
    if (
      ApplicationStore.unit === null ||
      ApplicationStore.judgeId?.toUpperCase() !== this.judgeId.toUpperCase()
    ) {
      ApplicationStore.getJudgeAssignment(
        new GetJudgeAssignmentParams(this.judgeId, this.unitId)
      );
    }
  }

  @Store.Getter
  public unit!: Unit;
}
